import React from "react";
import "bulma/css/bulma.css";

import Logo from "../../static/logo.svg";

export default () => (
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <img className="logo404" src={Logo} alt="logo" />

        <h1 class="title">404</h1>
        <h2 class="subtitle">The requested page doesn't exist at all!</h2>
      </div>
    </div>
  </section>
);
